import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import firebase from "firebase";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "*",
  //   redirect: "/login",
  // },
  {
    path: "/",
    redirect: "/buildings",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/tasks",
    name: "Tasks",
    components: {
      default: () => import("@/views/Tasks.vue"),
      appBar: () => import("@/components/CalendarAppBar.vue"),
      bottomNavigation: () => import("@/components/BottomNavigation.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/calendar",
    name: "Calendar",
    components: {
      default: () => import("@/views/Calendar.vue"),
      appBar: () => import("@/components/CalendarAppBar.vue"),
      bottomNavigation: () => import("@/components/BottomNavigation.vue"),
      leftDrawer: () => import("@/components/CalendarSideBar.vue"),
    },
    children: [
      {
        path: "booking",
        name: "CalendarBooking",
        components: {
          calendarForm: () => import("@/components/CalendarBooking.vue"),
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "shift",
        name: "CalendarShift",
        components: {
          calendarForm: () => import("@/components/CalendarShift.vue"),
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "meal",
        name: "CalendarMeal",
        components: {
          calendarForm: () => import("@/components/CalendarMeal.vue"),
        },
        meta: {
          requiresAuth: true,
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/buildings",
    name: "Buildings",
    components: {
      default: () => import("@/views/Buildings.vue"),
      appBar: () => import("@/components/BuildingsAppBar.vue"),
      bottomNavigation: () => import("@/components/BottomNavigation.vue"),
      leftDrawer: () => import("@/components/BuildingsSideBar.vue"),
      //rightDrawer: () => import("@/views/BuildingsDetail.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/buildings-detail",
    name: "BuildingsDetail",
    components: {
      default: () => import("@/views/BuildingsDetail.vue"),
      appBar: () => import("@/components/BuildingsDetailAppBar.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kitchen",
    redirect: "/kitchen/home",
  },
  {
    path: "/kitchen",
    name: "Kitchen",
    components: {
      default: () => import("@/views/Kitchen.vue"),
      appBar: () => import("@/components/KitchenAppBar.vue"),
      bottomNavigation: () => import("@/components/BottomNavigation.vue"),
      leftDrawer: () => import("@/components/KitchenSideBar.vue"),
    },
    children: [
      {
        path: "home",
        name: "KitchenHome",
        components: {
          kitchenView: () => import("@/views/KitchenHome.vue"),
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "inventory",
        name: "KitchenInventory",
        components: {
          kitchenView: () => import("@/views/Inventory.vue"),
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "meal-builder",
        name: "KitchenMealBuilder",
        components: {
          kitchenView: () => import("@/views/KitchenMealBuilder.vue"),
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "menu-builder",
        name: "KitchenMenuBuilder",
        components: {
          kitchenView: () => import("@/views/KitchenMenuBuilder.vue"),
        },
        meta: {
          requiresAuth: true,
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/docs",
    name: "Docs",
    components: {
      default: () => import("@/views/Docs.vue"),
      appBar: () => import("@/components/DocsAppBar.vue"),
      bottomNavigation: () => import("@/components/BottomNavigation.vue"),
      leftDrawer: () => import("@/components/DocsSideBar.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !(await firebase.getCurrentUser())) {
    next("Login");
  } else {
    next();
  }
});
export default router;
