import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import moment from "moment";
import { firestorePlugin } from "vuefire";
import { firebaseApp } from "./firebase/init";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export const bus = new Vue();

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
      store.commit("setUserId", user.uid);
      firebaseApp
        .firestore()
        .collection("users")
        .doc(user.uid)
        .get()
        .then((snapshot) => {
          const document = snapshot.data();
          store.commit(
            "setUserIsCoreMember",
            document.coreMember === true ? true : false
          );
          store.commit(
            "setUserIsAdmin",
            document.admin === true ? true : false
          );
        });
    }, reject);
  });
};

Vue.config.productionTip = false;

Vue.use(firestorePlugin);

new Vue({
  router,
  vuetify,
  store,
  firebaseApp,
  moment,
  render: (h) => h(App),
}).$mount("#app");
