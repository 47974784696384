import Vue from "vue";
import Vuex from "vuex";

import { vuexfireMutations, firestoreAction } from "vuexfire";
import { firebaseApp } from "../firebase/init";
//import firebase from "firebase/app";
import "firebase/firestore";

const db = firebaseApp.firestore();

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    userId: null,
    userIsCoreMember: false,
    userIsAdmin: false,
    count: 11,

    docs: [],
    path: {
      id: ["0", null, "0"],
      zoom: [{ transform: "scale(1.0) translate(0px, 500px)" }],
      externallyControlled: [true, false, true],
    },
    buildingBulkSelection: [],
    buildingSelectionAction: "Finished",
    buildingBulkRoomType: "guestRoom",
    buildingDetailPage: false,
    buildingDetailId: "0.1.0.17",
    buildingDetailNodeName: "lobby",

    drawerExtended: {
      calendar: false,
      buildings: false,
      kitchen: false,
      docs: false,
    },
    calendarBottomSheet: false,
    calendar: { title: "Test", focus: "", type: "month" },
    calendarDrawer: {
      currentCalendarDisplayMethod: 2,
      currentCalendarDisplayInfoType: [0],
    },
    editDoc: false,
    docsDrawer: {
      doc: 0,
    },
    kitchenDrawer: {
      section: 0,
    },

    scale: {
      transform:
        "translate(-50%, -51%) scale(" +
        Math.min(
          document.documentElement.clientWidth / 600,
          (document.documentElement.clientHeight - 80) / 1000
        ) +
        ")",
    },
  },
  mutations: {
    incrementBy(state, amount) {
      state.count += amount;
    },
    updateScale(state, scale) {
      state.scale = scale;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setUserIsCoreMember(state, userIsCoreMember) {
      state.userIsCoreMember = userIsCoreMember;
    },
    setUserIsAdmin(state, userIsAdmin) {
      state.userIsAdmin = userIsAdmin;
    },
    toggleDrawer(state, [page, newState]) {
      state.drawerExtended[page] = newState;
    },
    overlayClicked(state, overlay) {
      let pathIdArray = [...state.path.id];
      pathIdArray[0] = overlay.toString();
      state.path.id = pathIdArray;
    },
    toggleFloor(state) {
      let path = [...state.path.id];
      path[2] = path[2] === "0" ? "1" : "0";
      state.path.id = path;
    },
    zoomOut(state) {
      let zoom = state.path.zoom;
      let id = state.path.id;
      let externallyControlled = state.path.externallyControlled;
      let externalStateHolder = new Array();
      if (zoom.length > 1) {
        for (var i = 0; i < externallyControlled.length; i++) {
          if (externallyControlled[i]) externalStateHolder[i] = id[i];
        }
        zoom.pop();
        while (
          zoom.length > 1 &&
          (zoom[zoom.length - 1] === undefined ||
            zoom[zoom.length - 1] === null)
        ) {
          zoom.pop();
        }
        while (id.length > zoom.length) {
          id.pop();
        }
        for (var j = 0; j < externallyControlled.length; j++) {
          if (externallyControlled[j]) id[j] = externalStateHolder[j];
        }
        state.path.zoom = zoom;
        state.path.id = id;
      }
    },
    buildingTreeSelection(state, tree) {
      state.buildingBulkSelection = tree;
    },
    buildingSelectionAction(state, action) {
      state.buildingSelectionAction = action;
    },
    buildingBulkRoomType(state, roomType) {
      state.buildingBulkRoomType = roomType;
    },
    viewActionNode(state, [id, nodeName, newState]) {
      state.buildingDetailId = id;
      state.buildingDetailNodeName = nodeName;
      state.buildingDetailPage = newState;
      console.log(`Viewed Action Node`);
    },
    pathId(state, data) {
      state.path.id = [...data];
    },
    zoom(state, data) {
      state.path.zoom = [...data];
    },
    calendarToToday(state) {
      state.calendar.focus = "";
    },
    calendarViewDay(state, [focus, type]) {
      state.calendar.focus = focus;
      state.calendar.type = type;
    },
    calendarArrows(state, [nextOrPrevious]) {
      var date =
        state.calendar.focus === ""
          ? new Date()
          : new Date(state.calendar.focus);
      if (state.calendarDrawer.currentCalendarDisplayMethod === 1) {
        date.setDate(date.getDate() + nextOrPrevious * 7);
      } else if (state.calendarDrawer.currentCalendarDisplayMethod === 2) {
        date.setMonth(date.getMonth() + nextOrPrevious);
      } else {
        date.setDate(date.getDate() + nextOrPrevious);
      }
      state.calendar.focus = date;
    },
    updateCalendarBottomSheet(state, value) {
      state.calendarBottomSheet = value;
    },
    editDoc(state) {
      state.editDoc = !state.editDoc;
    },
    ...vuexfireMutations,
  },
  actions: {
    bindDocs: firestoreAction(({ bindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef(
        "docs",
        db.collection("docs").orderBy("key", "desc")
      );
    }),
    bindCleaningData: firestoreAction(({ bindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef("cleaningData", db.collection("rooms"));
    }),
  },
});

export default store;
